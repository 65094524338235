import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import navstyle from './navs.module.scss'


const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/">Home</NavItem>
      <div className = {navstyle.dropdown}>
      <ul className={navstyle.dropbtn}>
          Sports
          <div className={navstyle.dropdowncontent}>
            <a href="/nfl">National Football League</a>
            <a href="/mlb">Major League Baseball</a>
            <a href="/ncaab">College Basketball</a>
            <a href="/nba">National Basketball Association</a>
          </div>
      </ul>
      </div>
      <div className = {navstyle.dropdown}>
      <ul className={navstyle.dropbtn}>
          Horse Racing
          <div className={navstyle.dropdowncontent}>
            <a href="/horses">Hong Kong Horse Racing</a>
            <a href="/horseRatings">International Horse Rankings</a>
          </div>
      </ul>
      </div>
      <NavItem to="/blog">Blog</NavItem>
      <NavItem to="/publications">Publications</NavItem>
      <NavItem to="/about">About</NavItem>
    </>
  )
}

export default NavbarLinks
