import React from 'react'

import Header from './header'
import Footer from './footer'
import layoutStyles from './layout.module.scss'
import '../styles/index.scss'
import { Helmet } from "react-helmet" 


const Layout = (props) => {
    return (
        <div>
            <Helmet>
                <title>John Camardo</title>
            </Helmet>
            <Header />
            <div className={layoutStyles.container}>
                <div className={layoutStyles.content}>
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Layout