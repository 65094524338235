import React from 'react'

import Navbar from '../components/Navbar.js'


const Header = () => {

    return (
          
        <Navbar></Navbar>
        
    )
}

export default Header